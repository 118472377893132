import login from './login'
import my from './my'
import chat from './chat'
import role from './role'
export default {
    login,
    my,
    chat,
    role
}
