<template>
  <div class="components-navbar" :style="{background}">
    <div class="group">
      <van-icon
        name="arrow-left"
        size="22"
        color="#333333"
        v-if="needBack"
        @click="back"
      />
    </div>
    <span class="title">{{ title }}</span>
    <div class="group right">
      <template v-if="needRight">
        <slot>
          <div @click="add" style="display: flex; align-items: center;" v-if="!loading">
            <van-icon name="plus" />
            <span>新建</span>
          </div>
        </slot>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    
    source: {
      type: String,
      default: "",
    },
    length: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
    background:{
      type: String,
      default: "",
    },
    needBack: {
      type: Boolean,
      default: false,
    },
    needRight: {
      type: Boolean,
      default: true,
    },
     // 类型 0系统 1自定义
     type:{
      type: Number,
      default:1,
    },
    // 是否在加载中
    loading: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    console.log();
  },
  methods: {
    back() {
      this.$router.back();
    },
    add() {
      if (this.source == "role") {
        this.$router.push(`/roleDetail?type=${this.type}`);
      } else {
        this.$router.push(`/schat?time=话题 ${this.length+1}`);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.components-navbar {
  padding: 0 0.4rem;
  box-sizing: border-box;
  height: 1rem;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    color: #000000;
  }
  .group,
  .title {
    flex: 1;
    text-align: center;
    font-size: 0.32rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
  }
  .group {
    display: flex;
    align-items: center;
    font-size: 0.32rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #294bf9;

    span {
      margin-left: 0.1rem;
    }
  }
  .right {
    justify-content: flex-end;
  }
}
</style>