import {
  Button,
  Overlay,
  Popup,
  NavBar,
  CellGroup,
  Field,
  Tabs,
  Tab,
  DropdownMenu,
  DropdownItem,
  Cell,
  Switch,
  Collapse,
  CollapseItem,
  List,
  Loading,
  Toast,
  PullRefresh,
  Form,
  DatetimePicker,
  Steps,
  Step,
  Progress,
  ActionSheet,
  Swipe,
  SwipeItem,
  Pagination,
  PasswordInput,
  NumberKeyboard,
  Uploader,
  RadioGroup,
  Radio,
  Checkbox,
  CheckboxGroup,
  Dialog,
  Card,
  icon,
  Tabbar,
  TabbarItem,
  Search,
  Tag ,
  Calendar,
  Popover,
  Empty 
} from 'vant'

const vantComponent = function (Vue) {
  Vue.use(Button)
  Vue.use(Overlay)
  Vue.use(Popup)
  Vue.use(NavBar)
  Vue.use(CellGroup)
  Vue.use(Field)
  Vue.use(Tabs)
  Vue.use(Tab)
  Vue.use(DropdownMenu)
  Vue.use(DropdownItem)
  Vue.use(Cell)
  Vue.use(Switch)
  Vue.use(Collapse)
  Vue.use(CollapseItem)
  Vue.use(List)
  Vue.use(Loading)
  Vue.use(Toast)
  Vue.use(PullRefresh)
  Vue.use(Form)
  Vue.use(DatetimePicker)
  Vue.use(Steps)
  Vue.use(Step)
  Vue.use(Progress)
  Vue.use(ActionSheet)
  Vue.use(Swipe)
  Vue.use(SwipeItem)
  Vue.use(Pagination)
  Vue.use(PasswordInput)
  Vue.use(NumberKeyboard)
  Vue.use(Uploader)
  Vue.use(RadioGroup)
  Vue.use(Radio)
  Vue.use(Checkbox)
  Vue.use(CheckboxGroup)
  Vue.use(Dialog)
  Vue.use(Card)
  Vue.use(icon)
  Vue.use(Tabbar)
  Vue.use(TabbarItem)
  Vue.use(Search)
  Vue.use(Tag )
  Vue.use(Calendar )
  Vue.use(Popover )
  Vue.use(Empty )
}

export default vantComponent



