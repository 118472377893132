<template>
  <div id="app">
    <!-- 是否使用缓存 -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <!-- 返回顶部 -->
    <!-- <go-top></go-top> -->
  </div>
</template>

<script>
// import goTop from "@/components/public/goTop";
// 暴露组件
export default {
  name: "App",
  data() {
    return {};
  },
  components: {
    // goTop,
  }
};
</script>

<style lang="less">
</style>
