<template>
  <div class="components-role-card">
    
    <template v-if="needStatus">
      <van-loading type="spinner" color="#1989fa" v-if="loading" />
      <van-empty
        :description="text"
        image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
        v-if="!loading&&list.length==0"
      />
      <!--   -->
    </template>   
    <div
      class="card"
      v-for="(item, index) in list"
      :key="index"
      :style="{padding:needDesc?'.34rem .36rem':needAva?'.28rem .36rem':'.38rem .4rem'}"
      @click="nav(item)"
    >
      <div class="wraper basic-font">
        <div
          class="avater"
          :style="{ 'border-color': item.titleColor, color: item.titleColor }"
          v-if="needAva"
        >
          {{ item.title.slice(0, 4) }}
        </div>
        <div class="group">
          <span class="text-overflow">{{ item.title || item.titleName }}</span>
          <span class="desc text-overflow" v-if="needDesc">{{
            item.content
          }}</span>
        </div>
      </div>

      <van-icon name="arrow" color="#7e7e7e"/>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    source: {
      type: String,
      default: "",
    },
    redirect: {
      type: String,
      default: "",
    },
    list: {
      type: Array,
      default: () => [],
    },
    needStatus: {
      type: Boolean,
      default: true,
    },
    needAva: {
      type: Boolean,
      default: true,
    },
    needDesc: {
      type: Boolean,
      default: false,
    },
    loading:{
      type: Boolean,
      default: false,
    },
    // 类型 0系统 1自定义
    type:{
      type: Number,
      default:1,
    },
    text:{
      type:String,
      default:'暂无相关领域'
    }
  },
  methods: {
    nav(item) {
      if (this.source == "role") {
        if (item.type == 0 && !this.redirect) {
          this.$router.push(`/role?type=${item.type}`);
        } else {
          this.$router.push(`/roleDetail?id=${item.id}&type=${this.type}`);
        }
      } else {
        if (item.titleCode == "default") {
          this.$router.push(`/schat?id=${item.id}&is=true`);
        } else {
          this.$router.push(`/schat?id=${item.id}`);
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
::v-deep.van-empty{
  .van-empty__image{
    width: 1.6rem;
    height: 1.6rem;
  }
}
.van-loading {
  text-align: center;
}
.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.components-role-card {
  .card {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 6.68rem;
    background: #ffffff;
    box-shadow: 0 0.04rem 0.2rem 0 rgba(184, 185, 188, 0.3);
    border-radius: 0.2rem;
    font-size: 0.32rem;
    margin-bottom: 0.3rem;
    .wraper {
      width: 100%;
      display: flex;
      align-items: center;
      .avater {
        margin-right: 0.2rem;
        width: .64rem;
        height: .64rem;
        padding: .04rem ;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        background: #ffffff;
        border-width: 1.5px;
        border-style: solid;
        line-height: .2rem;
        font-size: .18rem;
        font-weight: 400;
        text-align: center;
        border-radius: 50%;
      }
      .desc {
        margin-top: 0.06rem;
        font-size: 0.26rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #7e7e7e;
      }
    }
    .group {
      width: 78%;
      display: flex;
      flex-direction: column;
    }
    .van-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0.4rem;
    }
  }
}
</style>