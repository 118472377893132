import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import vant_import from "./utils/vant-import"   // vant组件
import api from '@/api'   // api接口
import "@/assets/styles/reset.less";
import "@/assets/styles/common.less";
import "./permission";  // 全局路由守卫注册
import Tabbar from '@/components/tabbar'
import Navbar from '@/components/navbar'
import RoleCard from '@/components/role-card'
Vue.component("Tabbar",Tabbar)
Vue.component("Navbar",Navbar)
Vue.component("RoleCard",RoleCard)

Vue.config.productionTip = false

// 全局方法挂载
Vue.prototype.$api = api
Vue.use(vant_import)
new Vue({
  router,
  // store,
  data: {
    eventHub: new Vue() // 使用集中的时间处理器，一劳永逸的在任何组件调用事件发射，接受的方法,中央处理
  },
  render: h => h(App)
}).$mount('#app')
