import request from '@/utils/request'
import QS from 'qs'

let q = '/smart/api'

export default {


    //发送消息 等待答案

    // getAsk(query) {
    //     return request({
    //         url: "/mapi",
    //         method: "post",
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         data: QS.stringify({
    //             _gp: 'ask',
    //             _mt: 'answer',
    //             ...query
    //         }) 
    //     });
    // },

    // getAsk(query){
    //     return request({
    //         url:'/smart/api/ask/answer?q='+ query,
    //         method: "get",
    //     })
    // }
    // 获取模型列表
    getModelList(customerId) {
        return request({
            url: `${q}/chatPc/modelList?customerId=${customerId}`,
            method: "get",
        })
    },
       //修改模型
       updateModel(data) {
        return request({
            url: `${q}/chatPc/updateModel`,
            method: "post",
            data
        })
    },
     //模型详情
     getCurrentModel(params) {
        return request({
            url: `${q}/chatPc/modelDetail`,
            method: "get",
            params
        })
    },
    //流式获取答案
    getStreamAsk(data) {
        return request({
            url: `/chat/completions`,
            method: "post",
            data,
            responseType: "blob",
            timeout: 300000
        })
    },
    //获取答案
    getAsk(data) {
        return request({
            url: q + '/ask/answer',
            method: "post",
            data,
            timeout: 300000
        })
    },
    //问题列表
    getAskList(userId) {
        return request({
            url: q + '/topic/list?userId=' + userId,
            method: "get",
        })
    },
    //创建话题
    addAsk(data) {
        return request({
            url: q + '/topic/save',
            method: "post",
            data
        })
    },
    // 话题详情
    getTopicInfo(params) {
        return request({
            url: q + '/topic/detail',
            method: "get",
            params
        })
    },
    //打开话题
    getAskDetail(params) {
        return request({
            url: q + '/ask/record',
            method: "get",
            params
        })
    },

    //修改话题名称
    changeChatName(data) {
        return request({
            url: q + '/topic/update',
            method: "post",
            data
        })
    },
    //删除话题
    removeChat(id) {
        return request({
            url: q + '/topic/delete?id=' + id,
            method: "DELETE",
        })
    },
    //删除指定聊天记录
    delRecord(params) {
        return request({
            url: q + '/ask/removeRecord',
            method: "get",
            params
        })
    }


}
