import request from '@/utils/request'

let q = "/smart/api";

export default {

    //获取验证码
    getCode(data) {
        return request({
            url: q + '/verifyCode/get',
            method: "post",
            data
        })
    },

    //验证码登录
    codeLogin(data) {
        return request({
            url: q + '/login/mobile',
            method: "post",
            data
        })
    },

    //小程序登录
    wxLogin(data) {
        return request({
            url: q + '/login/mini/wechat',
            method: "post",
            data
        })
    },
    // 校验是否填写邀请码
    checkInvite(userId) {
        return request({
            url: `${q}/code/check?userId=${userId}`,
            method: "get"
        })
    },
    //绑定邀请码
    bindingInvite(data) {
        return request({
            url: q + '/code/bind',
            method: "post",
            headers: {
                isToken: false
            },
            data
        })
    },
    //微信h5登录
    wxLoginCode(data) {
        return request({
            url: q + '/login/native/wechat',
            method: "post",
            data
        })
    },
      // 苹果微信登录
      appleLogin(data) {
        return request({
            url: q + '/login/native/apple',
            method: "post",
            data
        })
    },
    // 苹果微信登录
    appleWXLogin(data) {
        
        return request({
            url: q + '/login/native/wechatApp',
            method: "post",
            data
        })
    },
    wxBindPhone(data) {
        return request({
            url: q + '/mobile/bind',
            method: "post",
            data
        })
    },




}

