import request from '@/utils/request'

let q = '/smart/api/role'

export default {
    //角色列表
    getRoleList(params){
        return request({
            url:q+'/list',
            method: "get",
            params,
            cancel:params.cancel
        })
    },
    // 角色详情
    getRoleInfo(params){
        
        return request({
            url:q+'/detail',
            method: "get",
            params
        })
    },
    updateRoleInfo(data){
        return request({
            url: q+'/update',
            method: "post",
            data
        })
    },
    addRoleInfo(data){
        return request({
            url: q+'/save',
            method: "post",
            data
        })
    },
    addCustom(){
        return request({
            url: q+'/join',
            method: "post",
            data
        })
    },
    // 获取角色(聊天列表)
    getRoleListForChat(params){
        return request({
            url:q+'/checkList',
            method: "get",
            params
        })
    },
    //删除角色
    delRole(params){
        return request({
            url:q+'/delete', 
            method: "delete",
            params
        })
    },
    //创建话题
    addAsk(data){
        return request({
            url: q+'/topic/save',
            method: "post",
            data
        })
    },
    // 话题详情
    getTopicInfo(params){
        return request({
            url:q+'/topic/detail',
            method: "get",
            params
        })
    },
     //打开话题
     getAskDetail(params){
        return request({
            url:q+'/ask/record',
            method: "get",
            params
        })
    },

    //修改话题名称
    changeChatName(data){
        return request({
            url: q+'/topic/update',
            method: "post",
            data
        })
    },
    //删除话题
    removeChat(id){
        return request({
            url:q+'/topic/delete?id='+ id,
            method: "DELETE",
        })
    },
     //删除指定聊天记录
     delRecord(params){
        return request({
            url:q+'/ask/removeRecord',
            method: "get",
            params
        })
    }


}
