import request from '@/utils/request'
import QS from 'qs'
import {
    postUpload
  } from '@/utils/request';


let q = "/smart/api";


export default {
         //获取用户信息
         getUserDetail(id){
            return request({
                url:q+'/user/detail?id='+ id,
                method: "get",
            })
        },
        //用户信息修改
        changeDetail(data){
          return request({
              url: q+'/user/update',
              method: "post",
              data
          })
        },
        //用户修改手机号
        changePhone(data){
          return request({
              url: q+'/user/mobile/update',
              method: "post",
              data
          })
        },
        //邀请码生成
        addInvite(data){
          return request({
              url: q+'/code/generate',
              method: "post",
              data
          })
        },
        //注销
        logout(data){
          return request({
              url: q+'/user/delete',
              method: "post",
              data
          })
        },
        // 获取js_api签名
        getSign(data){
          return request({
              url: q+'/pay/getJsApiSignature',
              method: "post",
              data
          })
        },
        // 创建订单
        createOrder(data){
          return request({
              url: q+'/pay/createOrder',
              method: "post",
              data
          })
        },
         //邀请码查询
      //    getUserDetail(id){
      //     return request({
      //         url:q+'/code/detail?id='+ id,
      //         method: "get",
      //     })
      // },
      // //邀请码生成\
      // createInvite(data){
      //   return request({
      //       url: q+'/code/generate',
      //       method: "post",
      //       data
      //   })
      // },


}