<template>
  <div class="components-tabbar">
    <div class="tabbar-item" v-for="(item,index) in list" :style="{color:activeIndex==index?'#294BF9':'#A6AAB2'}" @click="nav(item.path,index)">
      <div class="img-wraper">
        <img :src="activeIndex==index?item.activeIcon:item.icon" alt="" />
      </div>
      {{ item.name }}
    </div>
  </div>
</template>
<script>
export default {
  props:{
    activeIndex:{
      type:String,
      default:"0"
    }
  },
  data() {
    return {
      list: [
        {
          path: "/index",
          icon: require("@/assets/images/tabbar/icon_role.png"),
          activeIcon: require("@/assets/images/tabbar/icon_role_active.png"),
          name: "领域",
        },
        {
          path: "/scene",
          icon: require("@/assets/images/tabbar/icon_scene.png"),
          activeIcon: require("@/assets/images/tabbar/icon_scene_active.png"),
          name: "场景",
        },
        {
          path: "/my",
          icon: require("@/assets/images/tabbar/icon_my.png"),
          activeIcon: require("@/assets/images/tabbar/icon_my_active.png"),
          name: "我的",
        },
      ],
    };
  },
  methods:{
    nav(path,index){
      if(this.activeIndex==index) return
this.$router.replace(path)
    }
  }
};
</script>
<style lang="less" scoped>
.components-tabbar {
  padding-bottom:constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  // border-top: 1px solid #D9D9DA;
  box-shadow: 0 0.5px 0 0 rgba(0, 0, 0, 0.14) inset;
  position: fixed;
  z-index: 10;
  bottom:0;
  left: 0;
  width: 100%;
  height: .98rem;
  display: flex;
  background: white;
  .tabbar-item {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-size: 0.2rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    .img-wraper {
      text-align: center;
      width: 0.5rem;
      height: 0.5rem;
    }
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>