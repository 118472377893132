import axios from "axios";
import router from "../router";
import { Toast, Notify } from "vant";
import { getToken } from "@/utils/auth";
const cancelToken = axios.CancelToken;
let CancelController=null;
/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
  localStorage.clear()
  router.replace({
    path: '/login'
  })
}

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 30000,
});
// request拦截器
service.interceptors.request.use(
  config => {
     config.cancel&&CancelController&&CancelController()
    config.cancelToken=new cancelToken(c=>CancelController=c)
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false;
    if (getToken() && !isToken) {
      // config.headers["Authorization"] = "Bearer " + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
      config.headers["Authorization"] = getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
     
      // config.headers["X-Forwarded-For"] = window.location.hostname
    }

    if(config.url === '/smart/api/verifyCode/get' || config.url === '/smart/api/login/mobile' || config.url ===  '/smart/api/login/native/wechat' || config.url ===  '/smart/api/mobile/bind'){
      config.headers["Authorization"]  = undefined
    }


    // get请求映射params参数
    if (config.method === "get" && config.params) {
      // console.log(url,'url');
      let url = config.url + "?";
      for (const propName of Object.keys(config.params)) {
        const value = config.params[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value !== null && typeof value !== "undefined") {
          if (typeof value === "object") {
            for (const key of Object.keys(value)) {
              let params = propName + "[" + key + "]";
              var subPart = encodeURIComponent(params) + "=";
              url += subPart + encodeURIComponent(value[key]) + "&";
            }
          } else {
            url += part + encodeURIComponent(value) + "&";
          }
        }
      }
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    
    return config;
  },
  error => {
    // console.log(error);
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  response => {
 
    const res = response.data
    
    if (res.code != 200) {
    
      Toast({
        message: res.msg,
        duration: 1000
      })
      if (res.code === 401|| res.code === 403) {
        Toast({
          message: "登录过期，请重新登录",
          duration: 1000
        })

        setTimeout(() => {
                toLogin()
        }, 1000)
      }
     return Promise.reject(res)
    } else {
      
      
      return response.data;
    }
    
   
    
  },
  error => {
    let { message } = error;
    if(!message) {
      return  Promise.reject(error)
    }
    if (message == "Network Error") {
      message = "服务器连接异常";
    } else if (message.includes("timeout")) {
      message = "服务器请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "服务器" + message.substr(message.length - 3) + "异常";
    }
    Toast({
      message: message,
      type: "fail",
      duration: 5 * 1000
    });
    return Promise.reject(error);
  }
);


export default service;
