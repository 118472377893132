/**
 * 通用js方法封装处理
 */

export const methods = {
    // 判断设备环境
    deviceEnvir() {
        let u = navigator.userAgent
        let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // 判断是否是 android终端
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // 判断是否是 ios终端
        let isWeiChat = u.match(/MicroMessenger/i) == 'micromessenger' // 判断是否是微信环境
        if (isWeiChat === true) {
            return 'WEICHAT'
        }
        else if (isAndroid === true) {
            // 安卓
            return 'ANDROID'
        } else if (isIOS === true) {
            // IOS
            return 'IOS'
        }
        else {
            return 'PC'
        }
    },
    // 生成随机串
    randomString(len) {
        len = len || 32;
        var $chars =
            "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678"; /** **默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
        var maxPos = $chars.length;
        var pwd = "";
        for (var i = 0; i < len; i++) {
            pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return pwd;
    },
    // 生成秒级时间戳
    timest() {
        var tmp = Date.parse(new Date()).toString();
        tmp = tmp.substr(0, 10);
        return tmp;
    },
    // 将base64转换为文件
    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(",");
        var mime = arr[0].match(/:(.*?);/)[1];
        var bstr = atob(arr[1]);
        var n = bstr.length;
        var u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    },
    isWeChat() {
        //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
        var ua = window.navigator.userAgent.toLowerCase();
        //通过正则表达式匹配ua中是否含有MicroMessenger字符串
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            return true;
        } else {
            return false;
        }
    },
     //不同环境登录跳转
     toLogin() {
        const redirect=location.href.split("/#")[1].split("?")[0]
        if (this.isWeChat()) {
            wx.miniProgram.getEnv(function (e) {
                if (e.miniprogram) {
                    // alert(`redirect:${redirect}`)
                    wx.miniProgram.navigateTo({
                        url: `/pages/login/index?redirect=${redirect}`
                    });
                } else {
                    router.push({
                        path: "/login",
                        query: { redirect },
                    });
                }

            })
        } else {
            router.push({
                path: "/login",
                query: { redirect},
            });
        }

    },
    // 储存登录信息
    saveLoginInfo(removeStoreId){
        if (location.href.includes("AT")) {
            const findstr = ["AT", "CID", "M",'BI', "PN","OD"];
            const keys = location.href
              .split("?")[1]
              .split("&")
              .map((item, index) =>
                item.includes(findstr[index])
                  ? item.split("=")[1]
                  : `无 ${item.split("=")[0]} 参数`
              );
              localStorage.setItem("token", keys[0]);
              localStorage.setItem("id", keys[1]);
            localStorage.setItem("mobile", keys[2]);
            localStorage.setItem("personalNumber", keys[3]);
            localStorage.setItem("bind", keys[4]);
            // localStorage.setItem("openId", keys[4]);
            // removeStoreId&&localStorage.removeItem("storeId")
            // alert(JSON.stringify({token:keys[0],mobile:keys[2],personalNumber:keys[3],customerId:keys[1],openId:keys[4]}))
          }
    }
}