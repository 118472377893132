import Vue from 'vue'
import Router from 'vue-router'
import { Toast } from "vant";
Vue.use(Router)
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}
const routes = [
    // 重定向路由
    {
      path: "/test",
      component: (resolve) => require(['@/views/test/index'], resolve)
    },
  // 重定向路由
  {
    path: "/",
    redirect: "/login"
  },
  // 首页
  {
    name: "index",
    path: '/index',
    component: (resolve) => require(['@/views/quiz/index'], resolve)
  },
  // // 首页
  // {
  //   name: "index",
  //   path: '/index',
  //   component: (resolve) => require(['@/views/quiz/1.0.vue'], resolve)
  // },
  
  // 我的
  {
    name: "my",
    path: '/my',
    component: (resolve) => require(['@/views/my/index'], resolve),
    meta: {
      requireAuth: true
    },
  },
  {
    name: "phone",
    path: '/phone',
    component: (resolve) => require(['@/views/my/phone'], resolve),
    meta: {
      requireAuth: true
    },
  },
  //修改昵称
  {
    name: "changeName",
    path: '/changeName',
    component: (resolve) => require(['@/views/my/changeName'], resolve),
    meta: {
      requireAuth: true
    },
  },
  //修改职业
  {
    name: "changeJob",
    path: '/changeJob',
    component: (resolve) => require(['@/views/my/changeJob'], resolve),
    meta: {
      requireAuth: true
    },
  },
  //随便问问
  // {
  //   name: "quiz",
  //   path: '/quiz',
  //   component: (resolve) => require(['@/views/quiz/index'], resolve),
  //   meta: {
  //     requireAuth: true
  //   },
  // },
  // 登录
  {
    name: "login",
    path: "/login",
    component: (resolve) => require(['@/views/login/index'], resolve),
    // 需要登录才能进入的页面可以增加一个meta属性
    meta: {
      keepAlive: true,
      isUseCache: false
    }
  },
  //是否邀请
  {
    name: "inviteIs",
    path: '/inviteIs',
    component: (resolve) => require(['@/views/login/inviteIs'], resolve),
    meta: {
      requireAuth: true
    },
  },

    //绑定手机号
    {
      name: "bindPhone",
      path: '/bindPhone',
      component: (resolve) => require(['@/views/login/bindPhone'], resolve),
      meta: {
        requireAuth: true
      },
    },


  
  //邀请好友
  {
    name: "invite",
    path: '/invite',
    component: (resolve) => require(['@/views/invite/index'], resolve),
    meta: {
      requireAuth: true
    },
  },
  //个人设置
  {
    name: "information",
    path: '/information',
    component: (resolve) => require(['@/views/information/index'], resolve),
    meta: {
      requireAuth: true
    },
  },
  //聊天页
  {
    name: "chat",
    path: '/chat',
    component: (resolve) => require(['@/views/chat/index'], resolve),
    meta: {
      requireAuth: true
    },
  },
   //流式聊天页
   {
    name: "schat",
    path: '/schat',
    component: (resolve) => require(['@/views/chat/index_stream'], resolve),
    meta: {
      requireAuth: true
    },
  },
  //隐私
  {
    name: "privacy",
    path: '/privacy',
    component: (resolve) => require(['@/views/document/privacy'], resolve)
  },
//场景
{
  name: "scene",
  path: '/scene',
  component: (resolve) => require(['@/views/scene'], resolve)
},
// 角色
{
  name: "role",
  path: '/role',
  component: (resolve) => require(['@/views/role'], resolve)
},
// 角色
{
  name: "roleDetail",
  path: '/roleDetail',
  component: (resolve) => require(['@/views/role/detail'], resolve)
},
  
  
]

const router = new Router({
  mode:"history",
  // 保证每个页面一进入就在页面顶部--可配置
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // 如果有后退、前进功能，就保留浏览器原始位置
    } else {
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.body.scrollTop;
      }
      return { x: 0, y: to.meta.savedPosition || 0 };
    }
  },
  routes: routes
})


// 路由守卫 全部配置  在每个路由页面进入的时候，判断是否登录--权限配置
router.beforeEach((to, from, next) => {
  
  // const { isHuawei } = to.query;
  // if (isHuawei && isHuawei.length > 0) {
  //   localStorage.setItem("isHuawei", isHuawei);
  // }

  // if (to.meta.requireAuth) {
  //   if (localStorage.getItem("token")) {
      next();
  //   } else {
  //     Toast({
  //       message: "您还未登录，跳转登录页面中...",
  //       duration: 800,
  //       onClose: function () {
  //         next({
  //           path: "/login",
  //           query: { redirect: to.fullPath }
  //         });
  //       }
        
  //     });
  //   }
  // } else {
  //   next();
  // }
});

export default router;
